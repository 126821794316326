<template>
  <v-card class="nospace">
    <v-card-text class="nospace">
      <v-card-text class="nospace">
        <loading :active.sync="loading" />

        <v-layout style="border-top: 1px solid #efefef" class="p-16">
          <v-flex xs2 md3>
            <v-select
              v-model="searchTypes"
              :items="searchItems"
              flat
              label="Search Type"
              item-text="status"
              single-line
              class="pt-0"
              item-value="abbr"
              persistent-hint
              @change="searchByType()"
              height="10"
              return-object
              outline
            ></v-select>
          </v-flex>
          <v-flex xs2 md4 pr-3>
            <v-text-field
              height="55px"
              class="gap"
              label="Search here.."
              outline
              single-line
              :disabled="!searchTypes"
              v-model="search"
              @keyup.enter="searchText"
              @click:append="searchText"
              append-icon="search"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md3 pr-3>
            <v-select
              label="Transporter Type"
              :items="JobTypes"
              class="pt-0"
              style="padding-top: 12px !important"
              v-model="jobType"
              @change="searchJobType"
              single-line
            />
          </v-flex>
          <v-flex xs12 sm6 md3 pr-3>
            <v-select
              label="Registered As"
              :items="transporterType"
              class="pt-0"
              style="padding-top: 12px !important"
              v-model="type"
              @change="searchCategory"
              single-line
            />
          </v-flex>

          <v-flex xs1 class="reset-btn">
            <v-card-actions class="justify-center">
              <v-btn
                class="reset-btn-color"
                flat
                prepend-icon
                color="orange darken-1"
                @click.native="reset()"
              >
                Reset
                <v-icon color="orange">donut_large</v-icon>
              </v-btn>
            </v-card-actions>
          </v-flex>
          <v-flex xs5 pt-2>
            <v-btn
              v-permissions="'create-transporter'"
              class="white--text pull--right"
              flat
              color="orange darken-1"
              @click="check()"
            >
              <v-icon>add</v-icon>&nbsp;Register New Transporter
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-data-table
        id="TransporterListTable"
        hide-actions
        :headers="headers"
        :items="items"
        :disable-initial-sort="true"
        style="word-break: break-all"
        :pagination.sync="pagination"
      >
        <template slot="items" slot-scope="props">
          <td>
            {{ props.item.transporterId }}
            <activityLogs
              :type="'transporter'"
              :id="props.item.transporterId.toString()"
              :_id="props.item._id"
            />
          </td>
          <td>
            <img
              v-if="props.item.workingCountry == 'PAK'"
              src="../../assets/pakflag.png"
              height="20px"
            /><img
              v-if="props.item.workingCountry == 'KSA'"
              src="../../assets/ksaflag.png"
              height="20px"
            /><img
              v-if="props.item.workingCountry == 'UAE'"
              src="../../assets/Uaeflag.png"
              height="20px"
            />
            <img
              v-if="props.item.workingCountry == 'UAE LA'"
              src="../../assets/Uaeflag.png"
              height="20px"
              style="border-radius: 50%"
            />
            <br />{{ props.item.workingCountry || "-" }}
          </td>
          <td style="width: 10%">
            {{ props.item.transporterType || "-" }}
          </td>
          <td style="width: 10%">
            {{ getJobType(props.item.jobType) || "Land" }}
          </td>
          <td style="width: 20%">{{ props.item.companyName }}</td>
          <td>{{ getDate(props.item.registrationDate) }}</td>
          <td>
            <span v-if="props.item.deviceType == 'ADMIN PANEL'">
              Admin Panel</span
            >
            <span v-else-if="props.item.deviceType == 'WEB'">
              Transporter Panel</span
            >
            <span v-else>{{ props.item.deviceType }}</span>
          </td>
          <td style="width: 10%">{{ props.item.transporterName }}</td>
          <td
            style="width: 20%"
            v-if="props.item.phoneNo != null && props.item.countryCode != null"
          >
            {{ props.item.countryCode + "-" + props.item.phoneNo }}
          </td>

          <td v-else>-</td>
          <td style="width: 20%">{{ props.item.email || "-" }}</td>
          <td style="width: 20%">
            {{ props.item.action }}
            <button
              @click="
                editTransporter(
                  props.item.transporterId,
                  props.item._id,
                  props.item.workingCountry
                )
              "
              class="track-ass-btn green-button"
              title="Edit"
            >
              Details
            </button>

            <button
              v-permissions="'block-transporter'"
              @click="blockCustomer(props.item._id, 'false')"
              v-if="props.item.isBlocked"
              class="track-ass-btn mx-1 yellow-button"
              title="Unblock"
            >
              Unblock
            </button>

            <button
              v-permissions="'block-transporter'"
              @click="blockCustomer(props.item._id, 'true')"
              v-if="!props.item.isBlocked"
              class="track-ass-btn mx-1 red-button"
              title="Block "
            >
              Block
            </button>
            <button
              @click="verifyTransporter(props.item._id, 'true')"
              v-if="!props.item.isVerified"
              class="track-ass-btn my-1 blue-button"
              title="Verify"
            >
              Verify
            </button>

            <button
              v-if="props.item.isVerified"
              disabled
              class="track-ass-btn my-1 white-button"
              title="Verified"
            >
              Verified
            </button>
          </td>
        </template>
      </v-data-table>
      <pagination
        :totalPages="totalPages"
        :pages="this.pages"
        @pageNo="page"
        :url="`/dmsAdmin/transportList`"
        :callback="callback"
        :componentKey="componentKey"
        :action="`transporter`"
        :payload="payload"
        :type="1"
        v-on:pagination-load="checkLoading"
      />
      <success-dialog
        :content="x.message"
        :show="x.success"
        :onclose="closeMessage"
      />
      <v-snackbar
        :timeout="3000"
        color="red darken-2"
        v-model="error"
        class="white--text"
        v-if="error"
        >{{ error }}</v-snackbar
      >
      <SelectCountry
        :open="selectCountryDialog"
        @closeSelectCountry="selectCountryDialog = false"
        :type="'/transporter/new'"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import _ from "lodash";
import SelectCountry from "../Common/SelectCountry";
import { transporterList } from "@/constants/datatable-headers.js";
import { transporter } from "../../constants/api-urls";
import { EventBus } from "../../event-bus.js";
import activityLogs from "../Common/activityLogs";
import Loading from "vue-loading-overlay";
import SuccessDialog from "@/components/Common/SuccessDialog";
import ErrorBox from "@/components/Common/ErrorBox";
import moment from "moment";
import { StorageKeys, JobTypes, JobListTypes } from "../../constants/constants";
import { async } from "q";
import pagination from "@/components/Pagination/pagination";
export default {
  created() {
    this.$eventBus.$on("newtransporters", (value) => {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        let workingCountry = [];
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            workingCountry.push(key.value);
          }
        });
        this.workingCountry = JSON.stringify(workingCountry);
        this.componentKey += 1;
      }
    });
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = JSON.stringify(workingCountry);
      this.payload = {
        workingCountry: this.workingCountry,
      };
    }

    EventBus.$on("countryChanged", (val) => {
      this.workingCountry = JSON.stringify(val);
      this.payload.workingCountry = this.workingCountry;
      this.$eventBus.$emit("on-load");
    });

    if (localStorage.getItem("searchTransporter") !== null) {
      this.search = localStorage.searchTransporter;
      let searchTransporterType = JSON.parse(
        localStorage.searchTransporterType
      );
      this.searchTypes = searchTransporterType;
      this.payload = {
        searchText: this.search,
        searchType: this.searchTypes.abbr,
        workingCountry: this.workingCountry,
      };
    }
    if (this.$route.query && this.$route.query.workingCountry) {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.workingCountry = key.value;
          }
        });
      }

      let key = this.$route.query.workingCountry;
      EventBus.$emit("setCountriesfromUrl", key);
      this.workingCountries.forEach((element, index) => {
        if (element.value === key) {
          element.isSelected = true;
          this.newInquiry = 0;
          this.newJob = 0;
        } else element.isSelected = false;
      });
      localStorage.setItem(
        "workingCountries",
        JSON.stringify(this.workingCountries)
      );
    }
    this.JobTypes = JobTypes;

    if (!navigator.onLine) {
      this.loading = false;
      this.error = "Please check your internet connection";
      setTimeout(() => {
        this.error = "";
      }, 3000);
      return;
    }

    this.$eventBus.$on("stop-load", () => {
      this.loading = false;
    });
  },
  components: {
    pagination,
    SuccessDialog,
    ErrorBox,
    Loading,
    activityLogs,
    SelectCountry,
  },
  props: {
    loadingstop: Function,
    loader: Boolean,
  },

  data() {
    return {
      transporterType: [
        { value: "Transporter", text: "Transporter" },
        { value: "Broker", text: "Broker" },
      ],
      type: "",
      selectCountryDialog: false,
      workingCountry: null,
      JobTypes: [],
      operations: "",
      operationName: "",
      jobType: "",
      error: "",
      pages: 1,
      loading: true,
      dialog: false,
      dialog2: false,
      processing: false,
      x: {
        message: "",
        loading: true,
        success: false,
        menu: false,

        error: null,
        missingField: null,
      },
      search: "",
      componentKey: 0,
      totalPages: 0,
      pagination: {},

      payload: {},
      searchItems: [
        { status: "Company Name", abbr: "companyName" },
        { status: "Username", abbr: "userName" },
        { status: "Email", abbr: "email" },
        { status: "Phone Number", abbr: "mobileNumber" },
      ],
      searchTypes: "",
      headers: transporterList,
      items: [],
    };
  },
  watch: {
    pagination(val) {
      if (val["sortBy"] === "companyName") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "companyName";
          this.payload["sortType"] = -1;
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "companyName";
          this.payload["sortType"] = 1;
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        }
      }
      if (!val["sortBy"]) {
        delete this.payload["sortType"];
        delete this.payload["sortBy"];
        if (val["descending"] == null) {
          this.componentKey = this.componentKey + 1;
        }
      }
      deep: true;
    },
  },
  methods: {
    checkLoading(event) {
      if (event) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },
    page(event) {
      this.pages = event;
    },

    async check() {
      this.operationName = "create-transporter";
      let y = await this.checkOpertaionPermission(this.operationName);

      if (y) {
        if (localStorage.getItem("workingCountries")) {
          this.workingCountries = JSON.parse(
            localStorage.getItem("workingCountries")
          );
          let workingCountry = [];
          this.workingCountries.forEach((key, index) => {
            if (key.isSelected) {
              workingCountry.push(key.value);
            }
          });
          if (workingCountry.length === 1) {
            this.$router.push(`/transporter/new`);
          } else {
            this.selectCountryDialog = true;
          }
        }
      } else {
        return;
      }
    },
    getJobType(type) {
      switch (type) {
        case "1":
          return "Land";
        case "2":
          return "Air";
        case "3":
          return "Sea";
        default:
          return "Land";
      }
    },
    getDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    callback(res) {
      this.items = res.list;
      this.totalPages = Math.ceil(res.totalData / 15);
      this.pagination.rowsPerPage = 15;
      window.scrollTo(0, 0);
    },
    searchByType() {
      //  localStorage.setItem("transporterSerachType", this.searchTypes.abbr);
      this.$emit("filters-type", this.searchTypes.abbr);
      this.search = "";
      this.searchText();
    },
    searchCategory() {
      if (this.type) {
        // localStorage.setItem("transporterTypeFilter", this.type);
        this.$set(this.payload, "transporterType", this.type);
      }
    },
    searchJobType() {
      if (this.jobType) {
        //  localStorage.setItem("transporterJobType", this.jobType);
        this.$set(this.payload, "jobType", this.jobType);
      }
    },
    searchText: function () {
      //  localStorage.setItem("transporterSearch", this.search);
      if (this.search.trim() != "") {
        this.payload.searchType = this.searchTypes.abbr;

        this.$set(this.payload, "searchText", this.search);
      } else {
        delete this.payload.searchText;
        this.componentKey++;
      }
    },

    editTransporter(id, _id, country) {
      this.$router.push(`/transporter/edit/${id}/${_id}/${country}`);
    },

    paginationPayload(event) {
      this.payload = event;
      this.loading = true;
    },
    reset() {
      // localStorage.removeItem("searchTransporter");
      // localStorage.removeItem("transporterSerachType");
      // localStorage.removeItem("transporterTypeFilter");
      // localStorage.removeItem("transporterJobType");

      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
      if (this.search.trim() || this.jobType || this.type) {
        this.searchTypes = "";
        this.search = "";
        this.jobType = "";
        this.type = "";
        this.loading = true;
        this.payload = {
          workingCountry: this.workingCountry,
        };
      }
    },

    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
      this.$emit("on-block");
    },
    verifyTransporter(id, status) {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
      if (this.processing) {
        return;
      } else {
        this.processing = true;
        let blockUrl = transporter.verifyTender;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        let body = {
          transporterId: id.toString(),

          isVerified: status,
        };
        this.axios.post(this.constants.apiUrl + blockUrl, body, config).then(
          (response) => {
            this.x.success = true;
            this.processing = false;
          },
          (error) => {
            this.x.success = false;
            this.error = error.response.data.message;
          }
        );
      }
    },
    async blockCustomer(id, status) {
      this.operationName = "block-transporter";
      let y = await this.checkOpertaionPermission(this.operationName);

      if (y) {
        if (!navigator.onLine) {
          this.loading = false;
          this.error = "Please check your internet connection";
          setTimeout(() => {
            this.error = "";
          }, 3000);
          return;
        }
        if (this.processing) {
          return;
        } else {
          this.processing = true;
          let blockUrl = transporter.blockTransporter;
          delete this.axios.defaults.headers.common["token"];
          let token = localStorage.getItem(StorageKeys.SessionKey);
          let config = {
            headers: {
              authorization: `bearer ${token}`,
            },
          };

          let body = {
            transporterId: id.toString(),

            isBlocked: status,
          };
          this.axios.post(this.constants.apiUrl + blockUrl, body, config).then(
            (response) => {
              this.x.success = true;
              this.processing = false;
            },
            (error) => {
              this.x.success = false;
              this.x.error = error.response.data.message;
            }
          );
        }
      } else {
        return;
      }
    },
  },
};
</script>
<style lang="scss">
#TransporterListTable
  .theme--light.v-datatable
  thead
  th.column.sortable
  .v-icon {
  opacity: 0.3 !important;
  color: white !important;
  margin-left: 10px !important;
}
#TransporterListTable
  .theme--light.v-datatable
  thead
  th.column.sortable.active
  .v-icon {
  opacity: 1 !important;
  color: white !important;
  margin-left: 10px !important;
}
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}
</style>

<style scoped>
.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
}
</style>
